<template>
  <b-container class="get-quote" fluid>
    <div class="head-bg"></div>
    <div class="d-flex mt-4 mb-5" style="position:relative;height:88px">
      <logo class="mr-4"/>
      <align-middle><h1>BROKER PORTAL || Submit A Claim</h1></align-middle>
    </div>
    <div style="height:10px"></div>
    <h1 class="mt-5 text-center">Submit Claim Placeholder</h1>
  </b-container>
</template>

<script>
import Logo from '@/components/svgs/logo';
import AlignMiddle from '@/components/util/align-middle';
export default {
  data: () => {
    return {
      bg: 'white'
    };
  },
  name: 'SubmitClaim',
  components: {AlignMiddle, Logo},
  mounted() {
    document.title = 'Submit a Claim (LIO Insurance)';
  }
};
</script>

<style scoped>

</style>
